import { Link } from 'expo-router';
import { GestureResponderEvent, StyleSheet, View } from 'react-native';

import {
  Button,
  IconCartFill,
  IconCatering,
  IconDelivery,
  NoOrderDisplay,
  OrderDisplay,
  OrderDisplaySkeleton,
  ScrollView,
  YStack,
  YStackProps,
} from '@fhs/ui';
import Footer from '@fhs-legacy/frontend/src/components/footer';
import { isExternalLink } from '@fhs-legacy/frontend/src/utils/is-external-link';

import { HomeAlert } from './home-alert';
import {
  CareersCard,
  CateringCard,
  HookAndLadderCard,
  PublicSafetyFoundationCard,
  RapidRescueCard,
} from './marketing-cards-mobile';
import { TopMarketingImage } from './top-marketing-image-mobile';
import { ScreenHomeProps } from './types';

// This is a hack because react-native-web doesn't expose Image.resolveAssetSource https://reactnative.dev/docs/image#resolveassetsource
// We have to reach into react-native-web to the asset registry
// This file will _NOT_ work in native.
// We want the uri to begin with because react-native / react-native-web doesn't support tiling background images.
// Since this is a web only screen and tiling the background image is a requirement, I decided to use browser supported styles
// instead of the limited react-native styles. Since this sidesteps react-native / react-native-web we have to get the uri ourselves
// instead of having the library translate it for us.
/* eslint-disable-next-line import/order */
import { getAssetByID } from 'react-native-web/dist/modules/AssetRegistry';
const brickWallUri = (() => {
  const assetToUri = (asset: {
    httpServerLocation: string;
    name: string;
    type: string;
    hash: string;
  }) => `${asset.httpServerLocation}/${asset.name}.${asset.type}?hash=${asset.hash}`;

  return assetToUri(getAssetByID(require('./brick-wall-white.webp')));
})();

type CtaButtonStackProps = {
  style?: YStackProps['style'];
  isDeliveryEnabled: boolean;
  isCateringEnabled: boolean;
  shouldShowDelivery: boolean;
  cateringRedirectPath: string;
  pickUpRedirectPath: string;
  deliveryRedirectPath: string;
  onDeliverySelect: (event: GestureResponderEvent) => void;
};

function CtaButtonStack(props: CtaButtonStackProps) {
  return (
    <YStack style={styles.ctaButtonStack}>
      <Link href={props.pickUpRedirectPath} asChild>
        <Button type="solid" size="lg" testID="homepage-pickup-order">
          <Button.Icon>
            <IconCartFill />
          </Button.Icon>
          <Button.Text>Pick Up Order</Button.Text>
        </Button>
      </Link>

      {props.shouldShowDelivery && (
        <Link href={props.deliveryRedirectPath} asChild>
          <Button
            type="outline"
            size="lg"
            disabled={!props.isDeliveryEnabled}
            onPress={props.onDeliverySelect}
          >
            <Button.Icon>
              <IconDelivery />
            </Button.Icon>
            <Button.Text>Delivery Order</Button.Text>
          </Button>
        </Link>
      )}

      <Link
        href={props.cateringRedirectPath}
        asChild
        {...(isExternalLink(props.cateringRedirectPath) ? { target: '_blank' } : {})}
      >
        <Button type="outline" size="lg" disabled={!props.isCateringEnabled}>
          <Button.Icon>
            <IconCatering />
          </Button.Icon>
          <Button.Text>Catering Order</Button.Text>
        </Button>
      </Link>
    </YStack>
  );
}

function MarketingCards({
  cateringHref,
  pickUpHref,
  foundationHref,
  careersHref,
}: {
  cateringHref: string;
  pickUpHref: string;
  foundationHref: string;
  careersHref: string;
}) {
  return (
    <YStack style={styles.verticalCardStack}>
      <RapidRescueCard pickUpHref={pickUpHref} />
      <PublicSafetyFoundationCard foundationHref={foundationHref} />
      <CareersCard careersHref={careersHref} />
      <HookAndLadderCard pickUpHref={pickUpHref} />
      {Boolean(cateringHref) && <CateringCard cateringHref={cateringHref} />}
    </YStack>
  );
}

export function ScreenHomeMobile(props: ScreenHomeProps) {
  return (
    <ScrollView>
      <div
        style={{
          backgroundColor: 'white',
          backgroundImage: `url(${brickWallUri})`,
          backgroundRepeat: 'repeat',
          backgroundSize: '750px auto',
        }}
      >
        {props.alert && props.alert.text && (
          <HomeAlert text={props.alert.text} link={props.alert.link} />
        )}
        {props.userPointExpireAlert && props.userPointExpireAlert.text && (
          <HomeAlert
            text={props.userPointExpireAlert.text}
            link={props.userPointExpireAlert.link}
          />
        )}
        <View style={styles.contentContainer}>
          <View style={styles.topContent}>
            <TopMarketingImage style={styles.topMarketingImage} />

            <View style={styles.displayOrderContainer}>
              {props.displayedOrder && !props.displayedOrderCardLoading ? (
                <OrderDisplay
                  type={props.displayedOrder.type}
                  href={props.redirectHref}
                  storeName={props.displayedOrder.storeName}
                  items={props.displayedOrder.items}
                  serviceModeTitle={props.displayedOrder.serviceModeTitle}
                  reorderCallback={props.onReorder}
                  reorderLoading={props.reorderLoading}
                  logReorderCardPress={props.logReorderCardPress}
                />
              ) : (
                <>
                  {props.reorderLoading || props.displayedOrderCardLoading ? (
                    <OrderDisplaySkeleton />
                  ) : (
                    <NoOrderDisplay />
                  )}
                </>
              )}
            </View>

            <CtaButtonStack
              isCateringEnabled={props.isCateringEnabled}
              isDeliveryEnabled={props.isDeliveryEnabled}
              shouldShowDelivery={props.shouldShowDelivery}
              cateringRedirectPath={props.cateringRedirectPath}
              pickUpRedirectPath={props.pickUpRedirectPath}
              deliveryRedirectPath={props.deliveryRedirectPath}
              onDeliverySelect={props.onDeliverySelect}
            />
          </View>
          <MarketingCards
            cateringHref={props.cateringRedirectPath}
            pickUpHref={props.pickUpRedirectPath}
            foundationHref={props.foundationPath}
            careersHref={props.careersPath}
          />
        </View>
      </div>
      <Footer />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    maxWidth: 480,
    width: '100%',
    gap: 20,
    paddingTop: 48,
    marginHorizontal: 'auto',
    alignItems: 'center',
  },
  topContent: {
    width: '100%',
    alignItems: 'center',
    paddingHorizontal: 16,
    gap: 20,
  },

  topMarketingImage: {
    maxWidth: 297,
  },
  displayOrderContainer: {
    width: '100%',
  },
  ctaButtonStack: {
    gap: 8,
    width: '100%',
  },
  verticalCardStack: {
    width: '100%',
  },
});
