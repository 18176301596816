import { router } from 'expo-router';
import { useIntl } from 'react-intl';

import { ItemAvailabilityStatus } from 'enums/menu';
import { useToast } from 'hooks/use-toast';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { OfferEntryTypes } from 'utils/cart/types';
import { isTest } from 'utils/environment';
import { routes } from 'utils/routing';
import { TOAST_ITEM_ADDED_TO_CART } from 'utils/test-ids';

/**
 *
 * Returns a function to reorder a previous order.
 */
export const useHandleReorder = ({
  addMultipleItemsToCart,
  setPendingReorder,
  setReordering,
  storeHasSelection,
  setUnavailableCartEntries,
  setReorderedOrderId,
  cartEntries,
}) => {
  const mapBackendToFrontend = useMapToFrontendCartEntry();
  const { formatMessage } = useIntl();
  const toast = useToast();

  return async (order, url = routes.orders) => {
    if (order && storeHasSelection) {
      setReordering(true);
      setReorderedOrderId(order.rbiOrderId);
      const { reorderCartEntries, appliedOffers } = order.cart;

      const unavailableEntries = [];
      let containsExtras = false;

      // We need to remove the offers from the cart entries. This is a temporary solution to avoid problems with reordering offers.
      const reorderCartEntriesWithoutOffers = reorderCartEntries.filter(
        entry => !OfferEntryTypes.includes(entry.type)
      );

      const itemsToReorder = [];

      for (const entry of reorderCartEntriesWithoutOffers) {
        // Map backend entry to frontend
        const { cartEntry: frontendEntry, status } = await mapBackendToFrontend(entry, cartEntries);
        // If this is an extra we want to avoid adding it to the cart or unavailable items.
        // Simply mark this order as having an extra and go to the next entry
        if (status === ItemAvailabilityStatus.CART_EXTRA) {
          containsExtras = true;
          continue;
        }
        // Check if the item is available
        if (status === ItemAvailabilityStatus.AVAILABLE) {
          // Disable toast message for each item when it's reordering
          itemsToReorder.push(frontendEntry);
        } else {
          unavailableEntries.push(frontendEntry);
        }
      }

      addMultipleItemsToCart({ newCartEntries: itemsToReorder, shouldShowMessage: false });

      if (unavailableEntries.length) {
        setUnavailableCartEntries(unavailableEntries);
      }

      setReordering(false);
      setReorderedOrderId(null);
      setPendingReorder(null);

      // If the order had extras go to the extras modal in the cart
      if (containsExtras) {
        router.navigate(routes.cartAddExtras);
      } else if (unavailableEntries.length === 0) {
        router.navigate({
          pathname: routes.cart,
          params: { reorderHasOffers: Boolean(appliedOffers?.length) },
        });

        // For reordering, we want to display a different single message when adding items to cart
        toast.show({
          testID: TOAST_ITEM_ADDED_TO_CART,
          duration: isTest ? 9999999 : undefined,
          text: formatMessage({ id: 'addToCartSuccessReorder' }),
          variant: 'positive',
        });
      } else {
        // Navigate to the url provided if there are unavailable items
        router.navigate(url);
      }
    } else {
      // if there's no order or no order or store selection, navigate to where was initially intended
      router.navigate(url);
    }
  };
};
