export const poweradeVictoryBundleStartDate = new Date(2024, 6, 15); // July 15, 2024
export const poweradeVictoryBundleEndDate = new Date(2024, 7, 11, 23, 59, 59); // August 11, 2024
export const poweradeVictoryBundleHref = '/rewards/offers/d7ef3d01-5525-4428-90c0-6a7936a05cc5';

export const captainsClubStartDate = new Date(2024, 7, 12); // August 12, 2024
export const captainsClubEndDate = new Date(2024, 9, 6, 23, 59, 59); // October 6, 2024
export const captainsClubHref = '/menu/picker-b940d649-f114-4476-a7cd-73edb0a14393';

export const thanksgivingTurkeySubStartDate = new Date(2024, 8, 9); // September 09, 2024
export const thanksgivingTurkeySubEndDate = new Date(2024, 9, 21, 23, 59, 59); // October 21, 2024
export const thanksgivingTurkeySubHref = '/menu/picker-5f48ded3-29c1-4517-baaa-89d2950caccb';
