import { ReactNode } from 'react';

import { DatetimeShow } from '@fhs/ui';

import {
  captainsClubEndDate,
  captainsClubStartDate,
  poweradeVictoryBundleEndDate,
  poweradeVictoryBundleStartDate,
} from '../constants';

export type LtoContentUSProps = {
  poweradeVictoryBundleContent?: ReactNode;
  captainsClubContent?: ReactNode;
  default?: ReactNode;
};

export const LtoContentUS = ({
  captainsClubContent,
  poweradeVictoryBundleContent,
  default: defaultContent,
}: LtoContentUSProps) => {
  return (
    <>
      <DatetimeShow
        after={poweradeVictoryBundleStartDate}
        before={poweradeVictoryBundleEndDate}
        default={
          <DatetimeShow
            after={captainsClubStartDate}
            before={captainsClubEndDate}
            default={defaultContent}
          >
            {captainsClubContent}
          </DatetimeShow>
        }
      >
        {poweradeVictoryBundleContent}
      </DatetimeShow>
    </>
  );
};
