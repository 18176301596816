import React, { FC, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import { Text, XStack, YStack } from '@fhs/ui';
import { Box, Divider, Skeleton } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import Currency from 'components/currency';
import { DeliveryStatus } from 'generated/rbi-graphql';
import DonationsRow from 'pages/cart/your-cart/totals/donations-row';
import GrandTotal from 'pages/cart/your-cart/totals/grand-total';
import SubTotal from 'pages/cart/your-cart/totals/subtotal';
import TaxesAndFees from 'pages/cart/your-cart/totals/taxes-and-fees';
import TaxesRow from 'pages/cart/your-cart/totals/taxes-row';
import TipSelector from 'pages/cart/your-cart/totals/tip-selector';
import { useCartContext } from 'state/cart';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, useOrderContext } from 'state/order';
import { EventName, emitEvent } from 'utils/event-hub';

import { useTotalTipCalculations } from '../../your-cart/totals/use-total-tip-calculations';

import { TotalsContainer } from './styled';

const Separator = Divider.withConfig({
  marginX: 0,
  marginTop: '$4',
  thickness: '1',
});

const SkeletonLoader = () => (
  <Box my="$10">
    <Skeleton.Text lines={2} mb="$5" />
    <Skeleton h="1px" mb="$5" />
    <Skeleton.Text lines={1} />
  </Box>
);

export interface ITotals {
  serverOrder: IServerOrder | null;
  rewardApplied?: boolean;
  serviceMode: ServiceMode | null;
  isDisabled: boolean;
}

export const Totals: FC<React.PropsWithChildren<ITotals>> = ({
  serverOrder,
  serviceMode,
  isDisabled,
}) => {
  const enableHideTaxLine = useFlag(LaunchDarklyFlag.ENABLE_HIDE_TAX_LINE);

  const { formatMessage } = useIntl();
  const orderContext = useOrderContext();
  const {
    additionalDiscountAmount,
    cart,
    delivery,
    deliveryFee,
    deliveryFeeDiscount,
    hideTipAmount,
    loading,
    rewardDiscountAmount,
    subTotalCents = 0,
    serverOrderTip,
    taxCents = 0,
    isDeliveryOrder,
  } = useTotalTipCalculations({ serverOrder, serviceMode });

  const { selectedDonationCartEntry } = useCartContext();

  const { isCartTotalNegative = false } = orderContext?.calculateCartTotalWithDiscount?.() || {};

  const dropoffState = serverOrder?.delivery?.dropoff?.state;
  const deliveryLoading = serverOrder?.delivery?.status === DeliveryStatus.QUOTE_REQUESTED;
  const otherDiscountAmount = isCartTotalNegative ? 0 : additionalDiscountAmount;
  const rewardDiscount = isCartTotalNegative ? 0 : rewardDiscountAmount;

  const donationAmount = useMemo(
    () => selectedDonationCartEntry?.price || 0,
    [selectedDonationCartEntry]
  );

  const showItemDonationRow = donationAmount > 0;

  const showTips = isDeliveryOrder && !hideTipAmount;

  const priceIsLoading = loading || !serverOrder || (isDeliveryOrder && !serverOrder.delivery);

  useEffect(() => {
    if (!priceIsLoading) {
      emitEvent(EventName.CART_STEP_2_PRICES_LOADING_END);
    }
  }, [priceIsLoading]);

  if (priceIsLoading) {
    // if loading is true there may have been a pricing error
    // so we should not attempt to render values that depend
    // on the cart
    return <SkeletonLoader />;
  }

  return (
    <TotalsContainer accessible>
      {showTips && (
        <TipSelector
          subTotalCents={subTotalCents}
          otherDiscountAmount={additionalDiscountAmount}
          showTipPercentage={orderContext.shouldShowTipPercentage(
            subTotalCents + additionalDiscountAmount
          )}
          isDisabled={isDisabled}
        />
      )}

      {showItemDonationRow ? (
        <DonationsRow
          isLoading={loading}
          totalCents={donationAmount}
          message={formatMessage({ id: 'donation' })}
        />
      ) : null}

      <SubTotal
        cart={cart}
        isLoading={loading}
        otherDiscountAmount={otherDiscountAmount}
        rewardDiscount={rewardDiscount}
      />

      {showTips && (
        <YStack style={tipStyles.container}>
          <XStack style={tipStyles.tipRow} testID="tip-amount">
            <Text style={tipStyles.tipText}>{formatMessage({ id: 'tip' })}</Text>
            <Currency amount={orderContext.tipAmount} textProps={tipStyles.tipText} />
          </XStack>
        </YStack>
      )}

      {!enableHideTaxLine && !isDeliveryOrder ? (
        <TaxesRow taxCents={taxCents} isLoading={loading} />
      ) : null}

      {isDeliveryOrder ? (
        <TaxesAndFees
          deliveryFee={deliveryFee}
          deliveryFeeDiscount={deliveryFeeDiscount}
          donationCents={donationAmount}
          dropoffState={dropoffState}
          serviceFeeCents={delivery?.serviceFeeCents ?? 0}
          smallCartFeeCents={delivery?.smallCartFeeCents ?? 0}
          geographicalFeeCents={delivery?.geographicalFeeCents ?? 0}
          baseDeliveryFeeCents={delivery?.baseDeliveryFeeCents ?? 0}
          deliverySurchargeFeeCents={delivery?.deliverySurchargeFeeCents ?? 0}
          taxCents={taxCents}
          tipCents={orderContext.tipAmount}
          serviceMode={serviceMode}
          subTotalCents={subTotalCents}
          isLoading={loading || deliveryLoading}
        />
      ) : null}

      <Separator />
      <GrandTotal
        cart={cart}
        serverOrderTip={serverOrderTip}
        tipAmount={isDeliveryOrder && !hideTipAmount ? orderContext.tipAmount : 0}
      />
    </TotalsContainer>
  );
};

// New styles not using UCL components
const tipStyles = StyleSheet.create({
  container: {
    marginTop: 8,
  },
  tipRow: { justifyContent: 'space-between', marginBottom: 8 },
  tipText: { fontSize: 14, lineHeight: 18 },
});

export default Totals;
