import { router } from 'expo-router';
import { useMemo, useState } from 'react';

import { ScreenHome, ScreenHomeProps } from '@fhs/home';
import { OrderDisplayType } from '@fhs/ui/src/components/order-display/types';
import { SwitchServiceModeDialog } from '@fhs-legacy/frontend/src/components/switch-service-mode-dialog';
import { selectors, useAppSelector } from '@fhs-legacy/frontend/src/state/global-state';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { REORDER_HOMEPAGE_STARTED_LOG } from '@fhs-legacy/frontend/src/state/order/constants/reorder-logs';
import { useHomepageReorder } from '@fhs-legacy/frontend/src/state/order/hooks/use-homepage-reorder';
import { useReorderLogs } from '@fhs-legacy/frontend/src/state/order/hooks/use-reorder-logs';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import logger from '@fhs-legacy/frontend/src/utils/logger';
import { isDelivery as _isDelivery } from '@fhs-legacy/frontend/src/utils/service-mode';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';
import { useHrefs } from '../../use-hrefs';

const DAYS_TO_SHOW_POINT_EXPIRE_ALERT = 15;

export default function RootIndexPage() {
  const alert = useFlag(LaunchDarklyFlag.HOMEPAGE_ALERT) as ScreenHomeProps['alert'];
  const hideDeliveryCta = useFlag(LaunchDarklyFlag.TEMP_HIDE_DELIVERY_CTA);
  const { pickUpHref, cateringHref, foundationHref, deliveryHref, careersHref } = useHrefs();
  const { serviceMode } = useServiceModeContext();
  const isDelivery = _isDelivery(serviceMode);
  const { loyaltyUser } = useAppSelector(selectors.loyalty.selectLoyaltyUser);

  const { cartEntries } = useOrderContext();
  const { logReorderHomepageCtaPress, logReorderHomepageCardPress } = useReorderLogs();

  const [showChangeToDeliveryDialog, setShowChangeToDeliveryDialog] = useState(false);

  const {
    displayedOrder,
    handleReorderCallback,
    recentOrderLoading,
    isReorderingLoading,
    confirmLocationActionSheetComponent,
    confirmStoreActionSheetComponent,
  } = useHomepageReorder();

  const handleDeliverySelect = event => {
    if (!!deliveryHref && !isDelivery && cartEntries?.length > 0) {
      event.preventDefault();
      setShowChangeToDeliveryDialog(true);
    }
  };

  const displayedOrderCardRedirectHref =
    displayedOrder?.type === OrderDisplayType.REORDER
      ? `/receipt/${displayedOrder?.id}`
      : `/order-confirmation/${displayedOrder?.id}`;

  const handleReorder = () => {
    logReorderHomepageCtaPress();

    logger.info({
      message: REORDER_HOMEPAGE_STARTED_LOG,
      context: {
        displayedOrder,
        serviceMode,
      },
    });

    handleReorderCallback();
  };

  const userExpireAlert: ScreenHomeProps['alert'] = useMemo(() => {
    if (loyaltyUser?.pointsExpiryDateKey) {
      const now = new Date();
      const loyaltyPointExpireDate = new Date(loyaltyUser?.pointsExpiryDateKey);
      const diffInDays = Math.round(
        (loyaltyPointExpireDate.getTime() - now.getTime()) / (1000 * 3600 * 24)
      );
      if (diffInDays <= DAYS_TO_SHOW_POINT_EXPIRE_ALERT) {
        return {
          text: 'Your points will expire soon. \nMake a purchase to save them.',
          link: {
            href: '/rewards/details',
            text: 'Learn More',
          },
        };
      }
    }
    return null;
  }, [loyaltyUser]);
  return (
    <>
      <ScreenHome
        careersPath={careersHref}
        isCateringEnabled={Boolean(cateringHref)}
        isDeliveryEnabled={Boolean(deliveryHref)}
        displayedOrder={displayedOrder}
        shouldShowDelivery={!hideDeliveryCta}
        alert={alert ? { text: alert?.text, link: alert?.link } : undefined}
        userPointExpireAlert={userExpireAlert}
        pickUpRedirectPath={pickUpHref}
        cateringRedirectPath={cateringHref}
        deliveryRedirectPath={deliveryHref}
        onDeliverySelect={handleDeliverySelect}
        foundationPath={foundationHref}
        onReorder={handleReorder}
        displayedOrderCardLoading={recentOrderLoading}
        reorderLoading={isReorderingLoading}
        redirectHref={displayedOrderCardRedirectHref}
        logReorderCardPress={logReorderHomepageCardPress}
      />
      {confirmLocationActionSheetComponent}
      {confirmStoreActionSheetComponent}
      {showChangeToDeliveryDialog && (
        <SwitchServiceModeDialog
          showDialog
          onConfirm={() => router.navigate(deliveryHref)}
          onDismiss={() => setShowChangeToDeliveryDialog(false)}
        />
      )}
    </>
  );
}
