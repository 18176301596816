import { StyleSheet, View } from 'react-native';

import { OrderDisplay, ScrollView, XStack, tokens } from '@fhs/ui';
import Footer from '@fhs-legacy/frontend/src/components/footer';
import { region } from '@fhs-legacy/frontend/src/utils/environment';

import {
  captainsClubHref,
  poweradeVictoryBundleHref,
  thanksgivingTurkeySubHref,
} from './constants';
import { HomeAlert } from './home-alert';
import { LtoContentCA } from './lto-content/lto-content-ca';
import { LtoContentUS } from './lto-content/lto-content-us';
import {
  CareersCard,
  CateringCard,
  HookAndLadderCard,
  MontrealSmokedMeatCard,
  PoweradeVictoryBundleCard,
  PublicSafetyFoundationCard,
  RapidRescueCard,
} from './marketing-cards-desktop';
import { CaptainsClubCard } from './marketing-cards-desktop/captains-club-card';
import { ThanksgivingTurkeySubCard } from './marketing-cards-desktop/thanksgiving-turkey-sub-card';
import { ScreenHomeProps } from './types';

function MarketingCards({
  cateringHref,
  pickUpHref,
  foundationHref,
  careersHref,
}: {
  cateringHref: string;
  pickUpHref: string;
  foundationHref: string;
  careersHref: string;
}) {
  return (
    <>
      <RapidRescueCard pickUpHref={pickUpHref} />
      {region() === 'US' && (
        <LtoContentUS
          poweradeVictoryBundleContent={
            <PoweradeVictoryBundleCard pickUpHref={poweradeVictoryBundleHref} />
          }
          captainsClubContent={<CaptainsClubCard pickUpHref={captainsClubHref} />}
        />
      )}
      {region() === 'CA' && (
        <LtoContentCA
          default={<MontrealSmokedMeatCard pickUpHref={pickUpHref} />}
          thanksgivingTurkeySub={
            <ThanksgivingTurkeySubCard pickUpHref={thanksgivingTurkeySubHref} />
          }
        />
      )}
      <PublicSafetyFoundationCard foundationHref={foundationHref} />
      <XStack>
        <CareersCard careersHref={careersHref} />
        <HookAndLadderCard pickUpHref={pickUpHref} />
      </XStack>
      {Boolean(cateringHref) && <CateringCard cateringHref={cateringHref} />}
    </>
  );
}

export function ScreenHomeDesktop(props: ScreenHomeProps) {
  const hasAlert = Boolean(props.alert?.text);

  return (
    <ScrollView contentContainerStyle={styles.wrapper}>
      <View style={styles.mainContent}>
        {hasAlert && <HomeAlert text={props.alert.text} link={props.alert.link} />}
        {props.userPointExpireAlert && props.userPointExpireAlert.text && (
          <HomeAlert
            text={props.userPointExpireAlert.text}
            link={props.userPointExpireAlert.link}
          />
        )}
        {props.displayedOrder && (
          <OrderDisplay
            type={props.displayedOrder.type}
            href={props.redirectHref}
            storeName={props.displayedOrder.storeName}
            items={props.displayedOrder.items}
            style={styles.orderDisplay}
            serviceModeTitle={props.displayedOrder.serviceModeTitle}
            reorderCallback={props.onReorder}
            reorderLoading={props.reorderLoading}
            logReorderCardPress={props.logReorderCardPress}
          />
        )}
        <MarketingCards
          cateringHref={props.cateringRedirectPath}
          pickUpHref={props.pickUpRedirectPath}
          foundationHref={props.foundationPath}
          careersHref={props.careersPath}
        />
      </View>
      <Footer />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: tokens.colors.$white,
    flexGrow: 1,
  },
  orderDisplay: {
    marginVertical: 24,
    marginHorizontal: 32,
  },
  mainContent: {
    maxWidth: 1920,
    marginHorizontal: 'auto',
    width: '100%',
    flexGrow: 1,
  },
  horizontalHalfCardsStack: {
    justifyContent: 'space-between',
  },
});
