import { Image, ImageBackground, ImageStyle } from 'expo-image';
import { Link } from 'expo-router';
import { StyleProp, View } from 'react-native';

import { Button, createMqStyles, useMqSelect } from '@fhs/ui';

export type ThanksgivingTurkeySubCardProps = {
  pickUpHref: string;
};

export function ThanksgivingTurkeySubCard({ pickUpHref }: ThanksgivingTurkeySubCardProps) {
  const mqStyles = useMqStyles();
  const backgroundSource = useMqSelect(
    {
      $gteDesktopLg: require('./thanksgiving-turkey-sub-card-background-desktop.webp'),
    },
    require('./thanksgiving-turkey-sub-card-background-tablet.webp')
  );
  return (
    <ImageBackground
      alt="It's back! Thanksgiving Turkey Sub"
      source={backgroundSource}
      placeholder={{ thumbhash: '7DgKI4aUvXl/hziJf4X4ZYg' }}
      style={mqStyles.captainsClubBanner}
    >
      <View style={mqStyles.contentContainer}>
        <Image
          source={require('./thanksgiving-turkey-sub-card-logo.webp')}
          alt="Thanksgiving Turkey Sub"
          placeholder={{ thumbhash: '0aiGCogUR5h5hn9Id4909weXeoRyk8c' }}
          style={mqStyles.logo as StyleProp<ImageStyle>}
          contentFit="contain"
        />
        <Link href={pickUpHref} asChild style={mqStyles.ctaContainer}>
          <Button size="xl">
            <Button.Text>Order Now</Button.Text>
          </Button>
        </Link>
      </View>
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  captainsClubBanner: {
    $base: {
      aspectRatio: 1024 / 505,
    },
    $gteDesktopLg: {
      aspectRatio: 1920 / 757,
    },
  },
  contentContainer: {
    $base: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '48%',
      top: '21%',
      left: '48%',
    },
  },
  logo: {
    $base: {
      aspectRatio: 927 / 285,
      width: '100%',
    },
  },
  ctaContainer: {
    $base: {
      width: '45%',
      top: '12%',
    },
    $gteDesktopLg: {
      width: '32%',
    },
  },
});
